import { Col, Container, Row } from "react-bootstrap";
import Image from "next/image";
import styles from "./style.module.scss";
import { useKeenSlider } from "keen-slider/react"; 
import "keen-slider/keen-slider.min.css"; 
import { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";

const Customer = (props) => {
  const router = useRouter();
  const isTechnologyPage = router.route.includes("/technologies/");
  const { data } = props;
  const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

  const [imageUrl, setImageUrl] = useState("");
  const [height, setHeight] = useState(500);
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  
  // Store slider instance
  const sliderInstance = useRef(null);
  const sliderRef = useRef(null); 

  useEffect(() => {
    if (data?.background_image?.data?.attributes?.url) {
      setImageUrl(baseUrl + data.background_image.data.attributes.url);
    }
  }, [data, baseUrl]);

  useEffect(() => {
    const setView = () => {
      if (window.innerWidth > 1400) {
        setHeight(850);
      } else if (window.innerWidth < 996 && window.innerWidth > 768) {
        setHeight(980);
      } else {
        setHeight(500);
      }
    };

    setView();
    window.addEventListener("resize", setView);
    return () => window.removeEventListener("resize", setView);
  }, []);

  const [sliderRefCallback] = useKeenSlider(
    { initial: 0,
      loop: true,
      slides: { perView: 1.6, spacing: 30, align: "center", origin: "center", }, // FIX: Center align  // Allows overscroll effect
      centered: true,
      mode: "free",
      renderMode: "performance",
      autoplay: { delay: 1000, stopOnInteraction: false }, // Custom autoplay
      breakpoints: {
      
       
        "(max-width: 1024px)": {
          slides: { perView: 1.5, spacing: 10,  align: "center", origin: "center", }, // Adjust perView and spacing for medium screens
          loop: true,
        },
        "(max-width: 992px)": {
          slides: { perView: 1, spacing: 5 }, // Adjust perView and spacing for smaller screens
          loop: true,
        },
        "(max-width: 768px)": {
          slides: { perView: 1, spacing: 5 }, // Adjust perView and spacing for smaller screens
          loop: true,
        },

      },
      drag: true,
    },
    [
      (slider) => {
        sliderInstance.current = slider;
        slider.on("slideChanged", () => {
          setCurrentSlide(slider.track.details.rel);
        });
        slider.on("created", () => {
          setLoaded(true);
        });
      },
    ]
  );

  return (
    <section
      className={`${styles["customer-new-section"]} ${isTechnologyPage && styles["tech-customer-section"]}`}
      style={{ minHeight: `${height}px` }}
    >
      {data?.testimonials?.data?.length > 0 && (
        <Container className={styles["container"]}>
          <Row>
            <Col lg={12}>
              <div className={styles["customer-content"]}>
                <h2>{data.title}</h2>
                <p>{data.short_title}</p>
              </div>
            </Col>
            <Col lg={12}>
              <div className={`${styles["customer-right-content"]} ${styles["swiper-2"]}`}>
                {/* Navigation Arrows */}
                {loaded && (
                  <>
                  <button
                    onClick={() => sliderInstance.current?.prev()} 
                    className={`${styles["swiper-button-prev"]} absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full z-10`}
                  >
            
                  </button>
                
                  <button
                    onClick={() => sliderInstance.current?.next()} 
                    className={`${styles["swiper-button-next"]} absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full z-10`}
                  >
              
                  </button>
                </>
                
                )}

                <div className={styles["swiper"]}>
                  <div ref={sliderRefCallback} className="keen-slider">
                    {data?.testimonials?.data?.map((value, i) => {
                      let test = value.attributes;
                      const starArray = Array.from({ length: test.Rating }, (_, index) => index + 1);

                      return (
                        <div key={i} className={`${styles["testi-box"]} keen-slider__slide`}>
                          <div className={styles["slider-heading-right"]}>
                            <Image alt="quote icon" src="/images/quote.svg" height={48} width={66} />
                          </div>
                          <div className={styles["swiper-heading-text"]}>
                            <div>
                              <ul>
                                {starArray.map((star, ind) => (
                                  <li key={`${ind}_j`}>
                                    <Image
                                      alt="star"
                                      src="/images/home/star-slider.svg"
                                      height={20}
                                      width={20}
                                    />
                                  </li>
                                ))}
                              </ul>
                              <h3>{test.reviewTitle}</h3>
                            </div>
                          </div>
                          <p>{test.text}</p>
                          <h3 className={styles["author"]}>{test.author}</h3>
                          <div className={styles["author-position"]}>{test.AutherPosition}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};

export default Customer;
